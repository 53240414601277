@import '../mixin/breakpoint';

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(12, 1fr);

  > [class*='grid-col-'] {
    display: flex;
  }

  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      @for $i from 1 through 12 {
        .grid-col#{$infix}-#{$i} {
          grid-column: span $i;
        }

        .grid-col#{$infix}-order-#{$i} {
          order: $i;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    .hide#{$infix} {
      display: none !important;
    }

    .display#{$infix} {
      display: inherit !important;
    }
  }
}
