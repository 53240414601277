/* You can add global styles to this file, and also import other style files */
@import 'scss/index';

.file-upload {
  .simple-input-file {
    label {
      margin-bottom: 0;
    }
  }
}

[type='checkbox']:not(.eui-input-checkbox):not(.cdk-visually-hidden) + label {
  margin-top: 0;
  margin-bottom: 0;

  &::before,
  &::after {
    content: none;
  }
}

[type='checkbox']:not(.eui-input-checkbox):not(.cdk-visually-hidden):checked
  + label:not(:empty)::after {
  content: none;
}

.ecl-radio {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

[type='radio']:not(.eui-input-radio):not(.cdk-visually-hidden)::before {
  content: none;
}

[type='radio']:not(.eui-input-radio):not(.cdk-visually-hidden)
  + label:not(:empty)::before {
  content: none;
}

[type='radio']:not(.eui-input-radio):not(.cdk-visually-hidden)
  + label:not(:empty) {
  padding-left: 0;
}

/* stylelint-disable-next-line selector-class-pattern */
.ecl-select__multiple .ecl-select__multiple-all .ecl-checkbox__label {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.ecl-select__container,
.ecl-text-input,
.ecl-text-area {
  width: 100%;
}

.ecl-form-group {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* stylelint-disable selector-class-pattern */
.ecl-select__container {
  .ecl-select--invalid {
    &:focus {
      + .ecl-select__icon {
        background-color: var(--eui-base-color-danger-100);
      }
    }

    &:not(:focus) {
      border-color: var(--eui-base-color-danger-130);

      + .ecl-select__icon {
        background-color: var(--eui-base-color-danger-130);
      }
    }

    &:not(:focus):not(:hover) {
      border-color: var(--eui-base-color-danger-100);

      + .ecl-select__icon {
        background-color: var(--eui-base-color-danger-100);
      }
    }
  }
}
/* stylelint-enable selector-class-pattern */

/* stylelint-disable-next-line selector-class-pattern */
.ecl-checkbox svg {
  pointer-events: none;
}

.list {
  li {
    list-style-type: disc;
  }
}

.img-preview {
  max-width: 80%;
  max-height: 200px;
  object-fit: contain;
}

/* stylelint-disable-next-line selector-class-pattern */
.eui-tabs__items {
  background-color: var(--eui-base-color-grey-10);
}

.ecl-button {
  .ecl-spinner {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0.5rem 0 0;

    /* stylelint-disable-next-line selector-class-pattern */
    .ecl-spinner__loader {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
